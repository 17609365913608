import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/su/Personal/Projects/Playground/lottie-react/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import UseInteractivityBasic from "./UseInteractivityBasic.js";
import ScrollWithOffset from "./ScrollWithOffset.js";
import ScrollWithOffsetAndLoop from "./ScrollWithOffsetAndLoop.js";
import PlaySegmentsOnHover from "./PlaySegmentsOnHover.js";
import CursorDiagonalSync from "./CursorDiagonalSync.js";
import CursorHorizontalSync from "./CursorHorizontalSync.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uselottieinteractivity"
    }}>{`useLottieInteractivity`}</h1>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <p>{`Use this hook along with the `}<strong parentName="p">{`useLottie`}</strong>{` hook to add animations synced with
scroll and cursor`}</p>
    <p>{`Also read `}<a parentName="p" {...{
        "href": "https://lottiefiles.com/interactivity"
      }}>{`official lottie
reference`}</a>{` for general, non-react
solution.`}</p>
    <UseInteractivityBasic mdxType="UseInteractivityBasic" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie, useLottieInteractivity } from "lottie-react";
import likeButton from "./likeButton.json";

const style = {
  height: 300,
  border: 3,
  borderStyle: "solid",
  borderRadius: 7,
};

const options = {
  animationData: likeButton,
};

const Example = () => {
  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll",
    actions: [
      {
        visibility: [0.4, 0.9],
        type: "seek",
        frames: [0, 38],
      },
    ],
  });

  return Animation;
};

export default Example;
`}</code></pre>
    <h2 {...{
      "id": "params"
    }}>{`Params`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lottieObj`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Result returned from the useLottie() hook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Either "scroll" or "cursor". Event that will be synced with animation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of actions that will run in sequence (SEE BELOW)`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`actions`}</strong>{` is an array of objects that define how animation will
be run based on the chosen mode. One action chains the next one.`}</p>
    <p>{`An action object is defined as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  frames: [number] | [number, number];
  type: "seek" | "play" | "stop" | "loop";
  visibility?: [number, number];
  position?: { [axis in "x" | "y"]: number | [number, number] };
} 
`}</code></pre>
    <h3 {...{
      "id": "frames"
    }}>{`frames`}</h3>
    <p>{`Animation frame range to play for the action.`}</p>
    <p>{`Let's say full animation has 150 frames.
To sync all 150 frames with one action, you would pass `}{`[0, 150]`}{`.
To start animation from 50 frame and end at 120, you would pass `}{`[50, 120]`}{`.
To freeze animation at 80 frame, you would pass `}{`[80]`}{`.`}</p>
    <h3 {...{
      "id": "type"
    }}>{`type`}</h3>
    <p>{`Action type.`}</p>
    <p>{`'play', 'stop', 'loop' are pretty self-explanatory. With 'seek' passed, lottie
will play animation frame by frame as you scroll down the page (mode: "scroll")
or move cursor around (mode: "cursor").`}</p>
    <h3 {...{
      "id": "visibility"
    }}>{`visibility`}</h3>
    <p>{`Viewport of the action (mode "scroll" only)`}</p>
    <p>{`Each action has a start and end which is essentially a percentage for the height
of the lottie container and is a value between 0 and 1.
To start the action when animation is visible on the page, you would pass `}{`[0, 1]`}{`.
To start lottie after 40% scrolled and end at 85% scrolled, you would pass `}{`[0.4, 0.85]`}{`.`}</p>
    <h3 {...{
      "id": "position"
    }}>{`position`}</h3>
    <p>{`Cursor viewport (mode "cursor" only)`}</p>
    <p>{`You can define how much of the viewport cursor movement will cover inside the
animation element. To set cursor cover the entire animation element, you would
pass `}<inlineCode parentName="p">{`{ x: [0, 1], y: [0, 1]}`}</inlineCode>{`. To set cursor outside of the element, you would
pass `}<inlineCode parentName="p">{`{ x: -1, y: -1 }`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <h3 {...{
      "id": "reactelement"
    }}>{`React.Element`}</h3>
    <p>{`You only need to render the returned value.`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "lottie-scroll-with-offset"
    }}>{`Lottie scroll with offset`}</h3>
    <p>{`From 0 to 45% of the container the Lottie will be stopped, and from 45% to 100%
of the container the Lottie will be synced with the scroll.`}</p>
    <ScrollWithOffset mdxType="ScrollWithOffset" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie, useLottieInteractivity } from "lottie-react";
import likeButton from "./likeButton.json";

const options = {
  animationData: likeButton,
};

const Example = () => {
  const lottieObj = useLottie(options);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll",
    actions: [
      {
        visibility: [0, 0.45],
        type: "stop",
        frames: [0],
      },
      {
        visibility: [0.45, 1],
        type: "seek",
        frames: [0, 38],
      },
    ],
  });

  return Animation;
};

export default Example;
`}</code></pre>
    <h3 {...{
      "id": "scroll-effect-with-offset-and-segment-looping"
    }}>{`Scroll effect with offset and segment looping`}</h3>
    <p>{`In cases where you would like the animation to loop from a specific frame to a
specific frame, you can add an additional object to actions in which you can
specify the frames. In the example below, the Lottie loops frame 45 to 60 once
45% of the container is reached.`}</p>
    <ScrollWithOffsetAndLoop mdxType="ScrollWithOffsetAndLoop" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie, useLottieInteractivity } from "lottie-react";
import robotAnimation from "./robotAnimation.json";

const options = {
  animationData: robotAnimation,
};

const Example = () => {
  const lottieObj = useLottie(options);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll",
    actions: [
      {
        visibility: [0, 0.2],
        type: "stop",
        frames: [0],
      },
      {
        visibility: [0.2, 0.45],
        type: "seek",
        frames: [0, 45],
      },
      {
        visibility: [0.45, 1.0],
        type: "loop",
        frames: [45, 60],
      },
    ],
  });

  return Animation;
};

export default Example;
`}</code></pre>
    <h3 {...{
      "id": "play-segments-on-hover"
    }}>{`Play segments on hover`}</h3>
    <p>{`When the cursor moves in to the container, the Lottie loops from frame 45 to 60
as long as cursor is inside the container. The stop action as shown below is so
that the animation is stopped at the 45th frame when cursor is outside.`}</p>
    <PlaySegmentsOnHover mdxType="PlaySegmentsOnHover" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie, useLottieInteractivity } from "lottie-react";
import robotAnimation from "./robotAnimation.json";

const style = {
  height: 300,
  border: 3,
  borderStyle: "solid",
  borderRadius: 7,
};

const options = {
  animationData: robotAnimation,
};

const PlaySegmentsOnHover = () => {
  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "cursor",
    actions: [
      {
        position: { x: [0, 1], y: [0, 1] },
        type: "loop",
        frames: [45, 60],
      },
      {
        position: { x: -1, y: -1 },
        type: "stop",
        frames: [45],
      },
    ],
  });

  return Animation;
};

export default PlaySegmentsOnHover;
`}</code></pre>
    <h3 {...{
      "id": "sync-cursor-position-with-animation"
    }}>{`Sync cursor position with animation`}</h3>
    <p>{`Moving the cursor from top left of the container to the bottom right of the
container completes the animation. The seeking of the animation is synced to the
diagonal movement of the cursor.`}</p>
    <CursorDiagonalSync mdxType="CursorDiagonalSync" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie, useLottieInteractivity } from "lottie-react";
import robotAnimation from "./robotAnimation.json";

const style = {
  height: 300,
  border: 3,
  borderStyle: "solid",
  borderRadius: 7,
};

const options = {
  animationData: robotAnimation,
};

const CursorDiagonalSync = () => {
  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "cursor",
    actions: [
      {
        position: { x: [0, 1], y: [0, 1] },
        type: "seek",
        frames: [0, 180],
      },
    ],
  });

  return Animation;
};

export default CursorDiagonalSync;
`}</code></pre>
    <h3 {...{
      "id": "sync-animation-with-cursor-horizontal-movement"
    }}>{`Sync animation with cursor horizontal movement`}</h3>
    <p>{`Move your cursor on the animation below. You may interchange the x and y arrays
to get the vertical movement of the cursor synced with the animation.`}</p>
    <CursorHorizontalSync mdxType="CursorHorizontalSync" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie, useLottieInteractivity } from "lottie-react";
import hamsterAnimation from "./hamsterAnimation.json";

const style = {
  height: 300,
  border: 3,
  borderStyle: "solid",
  borderRadius: 7,
};

const options = {
  animationData: hamsterAnimation,
};

const CursorHorizontalSync = () => {
  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "cursor",
    actions: [
      {
        position: { x: [0, 1], y: [-1, 2] },
        type: "seek",
        frames: [0, 179],
      },
      {
        position: { x: -1, y: -1 },
        type: "stop",
        frames: [0],
      },
    ],
  });

  return Animation;
};

export default CursorHorizontalSync;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      